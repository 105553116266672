import React from "react"
import solutionsData from "../../../components/data/solutions"
import { Intent } from "../../../components/common/intent"
import orangeArrowImage from "../../../components/assets/img/ic-orange-arrow.png"

const Solutions = () => (
    <section className="solutions" id="solutions">
        <h1 className="title medium">
            CONHEÇA <span className="medium">NOSSAS SOLUÇÕES</span>
        </h1>
        <article className="wrapper">
            {solutionsData.map((item, index) => (
                <figure
                    key={index}
                    className="item"
                    style={{
                        background: `url(${item.IMG}) center/cover no-repeat`,
                    }}
                >
                    <div className="flex-box">
                        <img
                            className="arrow"
                            src={orangeArrowImage}
                            alt="arrow"
                        />
                        <span className="text medium">{item.Name}</span>
                    </div>
                    <Intent classes="button" to={`solucoes/${item.Slug}`}>
                        <span>Saiba Mais</span>
                    </Intent>
                </figure>
            ))}
        </article>
    </section>
)

export default Solutions
